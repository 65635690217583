<template>
  <div class="Twofa__Container">
    <Parallax :images="ParallaxImages()" />
    <div class="Form__Content">
      <div class="Form_Blur" />
      <div>
        <p class="Form__TitleText">2-STEPS VERIFICATION</p>
      </div>
      <div v-if="ready" class="FormElement">
        <h2 class="Element smallText">SCAN THIS with 2fa app :</h2>
        <img class="Element" :src="ans.secret.qr" alt="" />
        <div class="Element Rows">
          <p class="Element smallText">Secret code</p>
          <div class="CopyButton" @click="CopySecretCode()">
            <div class="CopyIcon" />
          </div>
        </div>
        <p class="Element Element_Data">{{ ans.secret.code }}</p>
        <div class="Element Rows">
          <p class="smallText">Recovery codes</p>
          <div class="CopyButton" @click="CopyRecoveryCodes()">
            <div class="CopyIcon" />
          </div>
        </div>
        <p class="smallText italic">
          (Please save this recovery code in a safe place, you will need one if
          you lose your phone)
        </p>
        <div class="recoveryList">
          <p
            class="RecoveryCode Element_Data"
            v-for="(element, index) in ans.recoveryCodes"
            :key="index"
          >
            {{ element }}
          </p>
        </div>
        <button class="ButtonElement" @click="submit()">VERIFY</button>
      </div>
      <InfiniteLoader class="Login__Loader" v-else />
    </div>
  </div>
</template>

<script>
import InfiniteLoader from '@/components/loader/InfiniteLoader.vue'
import Parallax from '@/components/parallax/Parallax.vue'
import api from '@/services/api'
import token from '@/mixins/token'

export default {
  mixins: [token],
  components: {
    InfiniteLoader,
    Parallax
  },
  data () {
    return {
      ans: '',
      ready: false
    }
  },
  methods: {
    ParallaxImages () {
      const result = []
      result.push({ src: 'Exterior_03.png', force: 0 })
      result.push({ src: 'Exterior_02.png', force: 10 })
      result.push({ src: 'Exterior_01.png', force: 30 })
      return result
    },
    ParallaxAnimations () {
      const result = []
      result.push({
        src: ['Birds_01.png', 'Birds_02.png', 'Birds_03.png'],
        force: -20
      })
      return result
    },
    submit () {
      this.$router.push({ name: 'twofa' })
    },
    CopySecretCode () {
      navigator.clipboard.writeText(this.ans.secret.code)
    },
    CopyRecoveryCodes () {
      let concat = ''
      for (let i = 0; i < this.ans.recoveryCodes.length; i++) {
        if (i > 0) {
          concat += ' '
        }
        concat += this.ans.recoveryCodes[i]
      }
      navigator.clipboard.writeText(concat)
    }
  },
  async mounted () {
    try {
      this.getToken()
      const res = await api.auth.handle2fa()
      this.ans = res.data
      this.ready = true
    } catch (err) {
      console.error(err)
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/main.scss';

.Twofa__Container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.Twofa__Image_Bg {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.Form__Content {
  position: relative;
  box-sizing: border-box;
  padding: 2rem 1.5rem;
  height: 350px;
  width: 365px;
  border-radius: 2.5rem;
  background-color: rgba(255, 255, 255, 0.5);
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-height: 610px) and (orientation: landscape) {
    height: 90%;
    padding: 0.5rem 1.5rem;
  }
  @media only screen and (max-width: 400px) {
    width: 90%;
  }
}

.Form_Blur {
  border-radius: 2.5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  backdrop-filter: blur(10px);
}

.Form__TitleText {
  font-family: 'Gotham Bold';
  @include fluid-type($min_width, $max_width, $min_font * 1.5, $max_font * 1);
  text-transform: uppercase;
  text-align: center;
  @media only screen and (max-height: 610px) and (orientation: landscape) {
    margin-bottom: 0.5rem;
  }
}

.subtitle {
  p {
    font-family: 'Gotham Book';
    @include fluid-type($min_width, $max_width, $min_font * 0.8, $max_font * 1);
    text-align: center;
  }
}

.FormElement {
  margin: auto;
  margin-top: 0.7em;
  flex-direction: row;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Element {
  margin: auto;
  margin-top: 1.5em;
}

.Rows {
  display: flex;
  flex-direction: row;
}

.smallText {
  font-weight: 600;
  font-size: 0.8em;
  margin: auto;
  margin-top: 0.5em;
}

.Bold {
  font-weight: 600;
  font-size: 1.2em;
  text-align: center;
}

.italic {
  font-style: italic;
}

.ButtonElement {
  margin: auto;
  margin-top: 2em;
  padding: 1em;
  font-size: 1em;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
}

.Element_Data {
  font-weight: 800;
  user-select: text;
  max-width: 90%;
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  overflow-wrap: break-word;
  text-align: center;
}

.FormButton {
  background: white;
  margin-top: 2em;
  padding: 0.6em;
  padding-left: 1em;
  padding-right: 1em;
  border: 2px grey solid;
  border-radius: 20px;
}

@media only screen and (min-width: 1000px) {
  .recoveryList {
    margin: auto;
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 10em;
    width: 30%;
    justify-content: center;
  }
}

@media only screen and (max-width: 1000px) {
  .recoveryList {
    margin: auto;
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 10em;
    width: 100%;
    justify-content: center;
  }
}

.RecoveryCode {
  margin: auto;
  width: 50%;
  max-height: 100px;
  text-align: center;
}

.Login__Loader {
  margin: auto;
  margin-top: 3em;
  width: 5em;
  height: 5em;
}

.CopyButton {
  width: 25px;
  height: 25px;
  background: white;
  border-radius: 100%;
  margin: auto;
  margin-left: 0.8em;
  display: flex;
  box-shadow: 1px 1px 20px grey;
}

.CopyIcon {
  margin: auto;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  background: black;
  width: 60%;
  height: 60%;
  mask-image: url('../../assets/icons/content-copy.svg');
}
</style>
