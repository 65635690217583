export default {
  methods: {
    setToken (token) {
      this.$store.dispatch('SetToken', token)
      this.$store.dispatch('StoreTokensLocally', token)
    },
    getToken () {
      return this.$store.getters.getToken
    }
  }
}
